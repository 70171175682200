import * as React from "react";
import "../css/About.css";

const About = () => {
  return (
    <div id="about-section">
      <div id="heading-container" className="heading-container">
        <span id="num-heading" class="ml-5 mt-3 number-heading">
          01.{" "}
        </span>
        <span
          id="work-heading"
          class=" ml-2 mt-3 font-weight-bold title-heading"
        >
          {" "}
          About Me
        </span>
        <div className="horizontal-line"></div>
      </div>
      <div class="about pt-4">
        <p>
          Hello! My name is Isaac and I love all things tech. As of recent, my
          focus is on software engineering (frontend/backend), cybersecurity , and linux. I enjoy
          creating projects from scratch, whether it's to learn a new skillset,
          or to find creative solutions to problems.
        </p>
        <p>
          My past experience includes working as a security engineer , a security
          internship at a healthcare corporation, volunteering as IT support,
          and helping K-12 students with math.{" "}
        </p>
        <p>
          Some of my passions outside of tech are music production, working on
          cars, playing video games, and fitness.
        </p>

        <p>Here are some languages and technologies I am comfortable using:</p>
        <ul class="technologies pt-3 pb-3">
          <div class="tech1">
            <li class="p-1">Python</li>
            <li class="p-1">JavaScript</li>
            <li class="p-1">React</li>
            <li class="p-1">Linux</li>
            <li class="p-1">bash</li>
            <li class="p-1">Azure</li>
            <li class="p-1">AWS</li>
            <li class="p-1">HTML</li>
            <li class="p-1">CSS</li>
            <li class="p-1">Tailwind</li>
            <li class="p-1">Bootstrap</li>
            <li class="p-1">Docker</li>
            <li class="p-1">Git</li>
            <li class="p-1">NextJS</li>
            <li class="p-1">SQL</li>
          </div>
          <div className="tech2">
            <li class="p-1">Figma</li>
            <li class="p-1">Security Auditing</li>
            <li class="p-1">Security Compliance</li>
            <li class="p-1">SIEM/EDR</li>
            <li class="p-1">SSDLC</li>
            <li class="p-1">Security Training</li>
            <li class="p-1">Risk Assessments</li>
            <li class="p-1">Security Frameworks</li>
            <li class="p-1">Incident Response</li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default About;
