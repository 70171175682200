import React, { useEffect, useRef } from "react";

const FadeInSection = ({ children, delay }) => {
  const [isVisible, setVisible] = React.useState(false);
  const sectionRef = useRef(null);

  const checkVisibility = () => {
    const section = sectionRef.current;
    if (section) {
      const rect = section.getBoundingClientRect();
      const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
      setVisible(isVisible);
    }
  };

  useEffect(() => {
    checkVisibility();
    window.addEventListener("scroll", checkVisibility);
    return () => window.removeEventListener("scroll", checkVisibility);
  }, []);

  return (
    <div
      ref={sectionRef}
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
    >
      {children}
    </div>
  );
};

export default FadeInSection;
