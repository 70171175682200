import * as React from "react";
import "../css/Projects.css";

const Projects = () => {
  const handleP0Click = () => {
    const newTab = window.open("https://zglambeauty.com", "_blank");
    newTab.focus();
  };

  const handleP1Click = () => {
    const newTab = window.open(
      "https://github.com/isaacmg00/spotlyrics",
      "_blank"
    );
    newTab.focus();
  };

  const handleP2Click = () => {
    const newTab = window.open("https://github.com/isaacmg00/amps", "_blank");
    newTab.focus();
  };

  const handleP3Click = () => {
    const newTab = window.open(
      "https://github.com/isaacmg00/project-productive",
      "_blank"
    );
    newTab.focus();
  };

  return (
    <div class="mt-5">
      {" "}
      <div class="">
        <div className="heading-container">
          <span id="num-heading" class="ml-5 mt-3 number-heading">
            03.{" "}
          </span>
          <span
            id="work-heading"
            class=" ml-2 mt-3 font-weight-bold title-heading"
          >
            {" "}
            My Projects
          </span>
          <div className="horizontal-line"></div>
        </div>

        <div class="project1 mt-4">
          <div class="project1-title">
            <span>
              <small class="project">Project</small>
              <br></br>
              <div>
                {" "}
                <small class="text-left project-title pb-3">zglam</small>{" "}
              </div>
            </span>
          </div>
          <div class="link-to-repo">
            <div class="p1-links">
              <a
                taget="_blank"
                rel="noreferrer"
                class="p-2"
                title="GitHub"
                href="https://github.com/isaacmg00/zglam-beauty"
              >
                <i class="fa fa-github links"></i>
              </a>
            </div>
          </div>
          <div id="p1" class="project1-description p-3">
            Website built for a client's beauty salon and spa. Designed from
            scratch with Figma and made with NextJS & React. Fully responsive
            and deployed to a custom domain using AWS.
            <br></br> <br></br>- Also set up Google Business and custom booking
            features
          </div>

          <div
            id="p0-img"
            onClick={handleP0Click}
            style={{ cursor: "pointer" }}
            class="project0-image"
          ></div>
          <div id="p1-tech" class="project1-tech">
            <small class="tech">Figma</small>
            <small class="ml-3 tech">React</small>
            <small class="ml-3 tech">NextJS</small>
            <small class="ml-3 tech">AWS</small>
          </div>
        </div>
        <div class="project1 mt-4">
          <div class="project1-title">
            <span>
              <small class="project">Project</small>
              <br></br>
              <div>
                {" "}
                <small class="text-left project-title pb-3">
                  spotlyrics
                </small>{" "}
              </div>
            </span>
          </div>
          <div class="link-to-repo">
            <div class="p1-links">
              <a
                taget="_blank"
                rel="noreferrer"
                class="p-2"
                title="GitHub"
                href="https://github.com/isaacmg00/spotlyrics"
              >
                <i class="fa fa-github links"></i>
              </a>
            </div>
          </div>
          <div id="p1" class="project1-description p-3">
            Real-time lyrics in your terminal for the current song you're
            listening to on Spotify. Want lyrics for the entire song at once?
            Yes. Want them synced line-by-line to your current playback? You
            bet. <br></br> <br></br>- Available as a python script or pip
            package (PyPI)
          </div>

          <div
            id="p1-img"
            onClick={handleP1Click}
            style={{ cursor: "pointer" }}
            class="project1-image"
          ></div>
          <div id="p1-tech" class="project1-tech">
            <small class="tech">Python</small>
            <small class="ml-3 tech">Spotify API</small>
            <small class="ml-3 tech">PyPI</small>
            <small class="ml-3 tech">OAuth 2.0</small>
            <small class="ml-3 tech">Wireshark</small>
          </div>
        </div>

        <div class="project1 mt-5 pb-5">
          <div class="project2-title">
            <span>
              <small class="project">Project</small>
              <br></br>
              <div>
                {" "}
                <small class="text-left project-title pb-3 ">amps</small>{" "}
              </div>
            </span>
          </div>
          <div class="link-to-repo">
            <div class="p1-links">
              <a
                taget="_blank"
                rel="noreferrer"
                class="p-2"
                title="GitHub"
                href="https://github.com/isaacmg00/amps"
              >
                <i class="fa fa-github links"></i>
              </a>
            </div>
          </div>
          <div id="p2" class="project2-description p-3">
            Amps is a lightweight SCA (software composition analysis) tool meant
            for scanning through all installed packages on an Arch Linux system
            and informing you which ones are vulnerable to CVEs. Install via
            pip.
            <br></br>
            <br></br>- Amps stands for <i>are my packages safe?</i>
          </div>
          <div
            id="p2-img"
            onClick={handleP2Click}
            style={{ cursor: "pointer" }}
            class="project2-image"
          ></div>
          <div id="p2-tech" class="project2-tech">
            <small class="tech">Linux</small>
            <small class="ml-3 tech">Vulnerability Scanning</small>
            <small class="ml-3 tech">Python</small>
            <small class="ml-3 tech">Shell</small>
          </div>
        </div>

        <div class="project1 mt-3">
          <div class="project3-title">
            <span>
              <small class="project">Project</small>
              <br></br>
              <div id="pt3">
                {" "}
                <small class="text-left project-title pb-3">
                  project-productive
                </small>{" "}
              </div>
            </span>
          </div>
          <div class="link-to-repo">
            <div class="p1-links">
              <a
                taget="_blank"
                rel="noreferrer"
                class="p-2"
                title="GitHub"
                href="https://github.com/isaacmg00/project-productive"
              >
                <i class="fa fa-github links"></i>
              </a>
            </div>
          </div>
          <div id="p3" class="project1-description p-3">
            Productivity oriented web app made with the PERN stack. Features a
            habit tracker, to-do list, pomodoro timer, and user login
            functionality. Responsible for the backend, while my teammates
            handled the rest.
            <br />
            <br /> - Final group project for my Advanced Web Design course
          </div>
          <div
            id="p3-img"
            onClick={handleP3Click}
            style={{ cursor: "pointer" }}
            class="project3-image"
          ></div>
          <div id="p3-tech" class="project2-tech">
            <small class="tech">PostreSQL</small>
            <small class="ml-3 tech">Node</small>
            <small class="ml-3 tech">Express</small>
            <small class="ml-3 tech">React</small>
          </div>
        </div>

        <div class="mt-5 more-projects title-heading">
          {" "}
          <span class="font-weight-bold">more stuff</span>
        </div>

        <div class="additional-projects-container">
          <div id="card" class="card p-3 hover-item p1">
            <div class="">
              <span>
                {" "}
                <small class="text-left project">Project</small>
                <a
                  taget="_blank"
                  rel="noreferrer"
                  class=""
                  title="GitHub"
                  href="https://github.com/isaacmg00/CNN-malware-detection"
                >
                  <i class="fa fa-github icon float-right github"></i>
                </a>
              </span>
              <p class="text-left title">Android Malware Detection</p>{" "}
            </div>
            <div class="description">
              TensorFlow implementation of a Convolutional Neural Network (CNN)
              for detection of Android malware presence with 99% accuracy
            </div>
            <div id="project-skills" class="inline-block mt-3">
              <br></br>
              <small class="tech">Python • Deep Learning • TensorFlow</small>
              <small></small>
            </div>
          </div>
          <div id="card" class="card p-3 hover-item p2">
            <div>
              <span>
                {" "}
                <small class="text-left project">Project</small>
                <a
                  taget="_blank"
                  rel="noreferrer"
                  class=""
                  title="GitHub"
                  href="https://github.com/isaacmg00/advisory-sample/security/advisories/GHSA-5682-q9q2-5rcm"
                >
                  <i class="fa fa-github icon float-right github"></i>
                </a>
              </span>
              <p class="text-left title">Vulnerability Report Sample</p>
              <div class="description">
                My attempt at writing an enhanced security advisory for two high
                severity vulnerabilities in open source libraries
              </div>
              <br />
              <div id="project-skills" class="mt-3">
                <small class="tech">CVE • CVSS • CWE • Documentation</small>
              </div>
            </div>
          </div>

          <div id="card" class="display-grid card p-3 hover-item p3">
            <span>
              {" "}
              <small class="text-left project">Project</small>
              <a
                taget="_blank"
                rel="noreferrer"
                class=""
                title="GitHub"
                href="https://github.com/isaacmg00/Yelp-Clone"
              >
                <i class="fa fa-github icon float-right github"></i>
              </a>
            </span>
            <p class="text-left title">Yelp-Clone</p>
            <div class="description">
              Basic PERN stack application allowing users to review restuarants
              and comment on them similar to Yelp&copy;. React frontend,
              Postgres database.
            </div>

            <div id="project-skills" class="inline-block mt-3">
              <br></br>
              <br></br>

              <small class="tech">PostgreSQL • Express • React • Node</small>
            </div>
          </div>

          <div id="card" class="card p-3 hover-item p4">
            <span>
              {" "}
              <small class="text-left project">Project</small>
              <a
                taget="_blank"
                rel="noreferrer"
                class=""
                title="GitHub"
                href="https://github.com/isaacmg00/542-final"
              >
                <i class="fa fa-github icon float-right github"></i>
              </a>
            </span>
            <p class="text-left title">Crypto Price Prediction</p>
            <div class="description">
              Application of a LSTM (Long Short-Term Memory) network on
              cryptocurrency assets with the goal of predicting future prices
            </div>
            <div id="project-skills" class="inline-block mt-3">
              <br></br>
              <br></br>

              <small class="tech">
                Machine Learning • Python • Numpy • Pandas
              </small>
            </div>
          </div>

          <div id="card" class="card p-3 hover-item p5">
            <span>
              {" "}
              <small class="text-left project">Project</small>
              <a
                taget="_blank"
                rel="noreferrer"
                class=""
                title="GitHub"
                href="https://github.com/isaacmg00/wal-rs"
              >
                <i class="fa fa-github icon float-right github"></i>
              </a>
            </span>
            <p class="text-left title">wal-rs</p>
            <div class="description">
              Script to automatically change the LED colors in your mouse and
              keyboard to match your current wallpaper
            </div>
            <div id="project-skills" class="inline-block mt-3">
              <small class="tech">Rust</small>
            </div>
          </div>

          <div id="card" class="card p-3 hover-item p6">
            <span>
              {" "}
              <small class="text-left project">Project</small>
              <a
                taget="_blank"
                rel="noreferrer"
                class=""
                title="GitHub"
                href="https://github.com/isaacmg00/karumadb"
              >
                <i class="fa fa-github icon float-right github"></i>
              </a>
            </span>
            <small class="text-left text-info">*in progress*</small>

            <p class="text-left title">KaruKaru</p>
            <div class="description">
              A site for auto enthusiasts to enjoy, post, and talk about cars.
            </div>

            <div id="project-skills" class="inline-block mt-3">
              <small class="tech">
                AWS • Django • React/React Native • PostgreSQL • Terraform
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
