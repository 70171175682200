import * as React from "react";
import "../css/Footer.css";

const Footer = () => {
  return (
    <div class="footer">
      <div class="mt-5 text-center">
        <a
          taget="_blank"
          rel="noreferrer"
          title="GitHub"
          href="https://github.com/isaacmg00"
        >
          <i class="fa fa-github icon2 p-4"></i>
        </a>
        <a
          taget="_blank"
          rel="noreferrer"
          title="LinkedIn"
          href="https://www.linkedin.com/in/isaacimg00"
        >
          <i class="fa fa-linkedin icon2 p-4"></i>
        </a>
        <a
          taget="_blank"
          rel="noreferrer"
          title="Email"
          href="mailto:isaacmg123@gmail.com"
        >
          <i class="fa fa-envelope icon2 p-4"></i>
        </a>
      </div>
      <div class="text-center">
        <a
          taget="_blank"
          rel="noreferrer"
          class="bio description"
          href="https://github.com/isaacmg00/personal-portfolio"
          id=""
        >
          <small class="text-center footer-text">
            Designed & Built by Isaac Garcia
          </small>
        </a>
      </div>
    </div>
  );
};

export default Footer;
