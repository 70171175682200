import * as React from "react";
import "../css/Intro.css";

const Intro = () => {
  return (
    <div class="intro">
      <div class="pt-5 ml-2 mr-2">
        <div class="">
          <div class="ml-4 p-2">
            <h2 class="display-text small-heading">Hey, I'm</h2>
            <h1 class="display-text heading">Isaac Garcia.</h1>
            <h3 class="display-text smaller-heading">
              I help build modern, responsive, and functional applications. Passionate software engineer and cybersecurity enthusiast.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
