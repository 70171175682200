import React, { useState } from "react";
import "../css/Work.css";

const Work = () => {
  const [selectedCompany, setSelectedCompany] = useState(0); // Setting the initial selected company to 0

  const companies = [
    {
      name: "TicketManager",
      role: "Software Engineer",
      tenure: "October 2024 - Present",
      description: ["TBD"],
    },
    {
      name: "Takumi Cloud",
      role: "Software Engineer",
      tenure: "February 2024 - August 2024",
      description: [
        "Deployed a fully featured salon website for clients to view a salon’s services, book appointments, and see reviews using React, NextJS, AWS, Figma, and third party integrations leading to a 20% increase in new clients",
        "Modernized the company landing page with responsive design, modern UI libraries, better browser/device compatibility significantly improving page load speed, look and feel, and security",
        "Migrated the company’s codebase from static HTML, CSS, and JavaScript to a React-based architecture, utilizing AWS services for deployment, and created a CI/CD pipeline and git flow for better maintainability and 99% uptime",
      ],
    },
    {
      name: "Neudesic",
      role: "Application Security Engineer",
      tenure: "October 2023 - February 2024",
      description: [
        "Assessed the existing security framework for an accounting firm alongside the Security Architect and engineering teams to secure world class applications",
        "Executed a quantitative and qualitative risk assessment through comprehensive gap analysis and recommendation of industry standard protocols and solutions",
        "Trained software architects and engineers on cutting-edge security best practices, fostering a culture of proactive security awareness and implementation within the team",
      ],
    },
    {
      name: "Teladoc Health",
      role: "Security Analyst Intern",
      tenure: "April 2022 - September 2022",
      description: [
        "Identified and reported findings in IDS, SIEM, and EDR configurations to the GRC Team, and learned issue remediation efforts with cross-functional teams",
        "Audited monthly employee termination testing for the Governance, Risk, and Compliance (GRC) team for 40 company applications",
        "Documented and reported control audit findings in technical detail and within strict deadlines, ensuring compliance with ISO27K, HIPAA, and NIST standards, escalating any issues to the IT department",
        "Assessed company vulnerability management tools and configurations to balance effectiveness and minimize false positives",
      ],
    },
    {
      name: "Kumon",
      role: "Math Tutor",
      tenure: "May 2017 - July 2017",
      description: [
        "Helped K-12 students with math topics ranging from basic counting to algebra, statistics, and calculus",
        "Graded student assignments and classwork alongside 1-on-1 assistance",
      ],
    },
  ];

  return (
    <>
      <div className="heading-container">
        <span id="num-heading" class="ml-5 mt-3 number-heading">
          02.{" "}
        </span>
        <span
          id="work-heading"
          class=" ml-2 mt-3 font-weight-bold title-heading"
        >
          {" "}
          Where I've Worked
        </span>
        <div className="horizontal-line"></div>
      </div>

      <div className="company-description-container">
        <div className="button-container">
          {companies.map((company, index) => (
            <div id="company-selector" key={index}>
              <button
                id={
                  selectedCompany === index
                    ? "active-button"
                    : "inactive-button"
                }
                onClick={() => setSelectedCompany(index)}
              >
                {company.name}
              </button>
            </div>
          ))}
        </div>
        <div id="description-container">
          {selectedCompany !== null && (
            <div id="work-description">
              <span class="role">{companies[selectedCompany].role} </span>
              <span class="company-name">
                @ {companies[selectedCompany].name}
              </span>
              <p class="tenure">{companies[selectedCompany].tenure}</p>
              <ul id="work-list">
                {companies[selectedCompany].description.map((desc, index) => (
                  <li key={index}>{desc}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div>
        <div id="education-container">
          <span id="education-header" class="font-weight-bold title-heading">
            Education
          </span>
        </div>

        <div id="csun" class="education mr-5">
          <div class="title-experience ">
            <div>
              {" "}
              <p class="role font-weight-bold">
                Georgia Institute of Technology
              </p>
              <p class="degrees-alt">Master of Science, Computer Science </p>
            </div>
          </div>
          <div class="date-attended">
            <span class="pr-2 company-name font-weight date-attended">
              from:
            </span>
            <span id="date" class="inline-flex degrees date-attended dates">
              2024-present
            </span>
          </div>
        </div>

        <div id="csun" class="education mr-5">
          <div class="title-experience ">
            <div>
              {" "}
              <p class="role font-weight-bold">
                California State University, Northridge
              </p>
              <p class="degrees-alt">Bachelor of Science, Computer Science </p>
              <p class="degrees-alt">Minor, Mathematics </p>
            </div>
          </div>
          <div class="date-attended">
            <span class="pr-2 company-name font-weight date-attended">
              from:
            </span>
            <span id="date" class="inline-flex degrees date-attended dates">
              2018-2022
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Work;
