import * as React from "react";
import "../css/Contact.css";

const Contact = () => {
  return (
    <div class="ml-4 mr-4 mt-5">
      {" "}
      <div class="">
        <div className="heading-container">
          <span class="ml-4 mt-3 number-heading">04. </span>
          <span class=" ml-2 mt-3 font-weight-bold title-heading">
            {" "}
            Contact
          </span>
          <div className="horizontal-line"></div>
        </div>
        <div class="contact">
          <h1 class="contact-heading">Get In Touch</h1>
          <p class="pt-2 description">
            Drop me a line via e-mail if you have any questions or just want to
            chat. I'll do my best to get back to you as soon as possible!
          </p>
          <form
            title="Email"
            action="mailto:isaacmg123@gmail.com"
            taget="_blank"
            rel="noreferrer"
          >
            <button type="submit" class="email-btn">
              email me
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
