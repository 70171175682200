import React from "react";
import "./App.css";

import FadeInSection from "./components/FadeInSection";
import Intro from "./components/Intro";
import About from "./components/About";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Work from "./components/Work";
//import Experience from "./components/Experience";

const App = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="app-body">
        <FadeInSection>
          <Intro />
        </FadeInSection>
        <div id="about">
          <FadeInSection>
            <About />
          </FadeInSection>
        </div>
        <div id="experience">
          <FadeInSection>
            <Work />
          </FadeInSection>
        </div>
        <div id="work">
          <FadeInSection>
            <Projects />
          </FadeInSection>
        </div>
        <div id="contact">
          <FadeInSection>
            <Contact />
          </FadeInSection>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
