import React, { useState, useEffect } from "react";
import "../css/Navbar.css";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

const Navbar = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const scrolledUp = prevScrollPos > currentScrollPos;

      if (currentScrollPos < 300 || scrolledUp) {
        setVisible(true);
      } else {
        setVisible(false);
      }
      setPrevScrollPos(currentScrollPos);
    };

    const handleScrollThrottled = throttle(handleScroll, 9);

    window.addEventListener("scroll", handleScrollThrottled);

    return () => {
      window.removeEventListener("scroll", handleScrollThrottled);
    };
  }, [prevScrollPos]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const throttle = (callback, delay) => {
    let previousCall = new Date().getTime();
    return function () {
      const time = new Date().getTime();

      if (time - previousCall >= delay) {
        previousCall = time;
        callback();
      }
    };
  };

  return (
    <div
      class="header"
      className={`navbar ${visible ? "navbar-visible" : "navbar-hidden"}`}
      style={{
        backgroundColor: "rgba(22, 24, 25 0.8)",
        top: "0",
        position: "fixed",
        width: "100%",
        backdropFilter: "blur(5px)",
        height: "64px",
      }}
    >
      <div
        id="scroll-button"
        title="Scroll to top"
        class="p-2"
        onClick={scrollToTop}
        style={{ cursor: "pointer", position: "absolute", left: "20px" }}
      >
        <KeyboardDoubleArrowUpIcon />
      </div>
      <div style={{ cursor: "pointer", position: "absolute", right: "20px" }}>
        <a
          class="p-2 header-text"
          href="#about"
          style={{
            paddingRight: "20px",
            textDecoration: "none",
            right: "20px",
          }}
        >
          <small class="header-nums">01.</small> About
        </a>
        <a
          class="p-2 header-text"
          href="#experience"
          style={{
            paddingRight: "20px",
            textDecoration: "none",
            right: "20px",
          }}
        >
          <small class="header-nums">02.</small> Experience
        </a>
        <a
          class="p-2 header-text"
          href="#work"
          style={{
            paddingRight: "20px",
            textDecoration: "none",
            right: "20px",
          }}
        >
          <small class="header-nums">03.</small> Work
        </a>
        <a
          class="p-2 header-text"
          href="#contact"
          style={{
            textDecoration: "none",
            right: "20px",
          }}
        >
          <small class="header-nums">04.</small> Contact
        </a>
      </div>
    </div>
  );
};

export default Navbar;
